import ChoosePassenger from '@/components/desktop/ChoosePassenger';
import PopperSelect from '@/components/desktop/PopperSelect';
import { adjustAllPassengers } from '@/slices/area/slices/searchFlightFormSlice';
import { adjustPassenger } from '@/slices/bus/area/slices/searchBusFormSlice';
import { useAppDispatch, useAppSelector } from '@/utils/hook';
import React from 'react';
import { PassengerIcon } from 'public/icons';
import { style } from '@/styles/globals';
import { Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import qs from 'qs';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';

const ChoosePassengerContainer = ({
  vehicleType, lang, switchUpdateSecondTime, isFirstTime, statePassenger,
}: { switchUpdateSecondTime?: any, vehicleType?: 'bus' | 'flight', lang?: ILangObj, isFirstTime?: boolean;
  statePassenger?: any, }) => {
  const router = useRouter();

  let { passenger: stateFlightPassenger } = useAppSelector(
    (state) => state.searchFlightForm,
  );
  if (isFirstTime) {
    stateFlightPassenger = statePassenger;
  }
  const { passenger: stateBusPassenger } = useAppSelector(
    (state) => state.searchBusForm,
  );

  const dispatch = useAppDispatch();
  const totalPassenger = vehicleType === 'bus' ? stateBusPassenger.adt + stateBusPassenger.chd + stateBusPassenger.inf : stateFlightPassenger.adt + stateFlightPassenger.chd + stateFlightPassenger.inf;
  const adjustPassengerFunc = (value: any) => {
    if (vehicleType === 'bus') {
      dispatch(adjustPassenger(value));
    } else {
      dispatch(adjustAllPassengers(value));
    }
    const { query, asPath } = router;
    if (asPath.includes('/route-flight')) {
      query['passenger[adt]'] = value?.adt || '1';
      query['passenger[chd]'] = value?.chd || '0';
      query['passenger[inf]'] = value?.inf || '0';
      // query['path[0]'] = undefined;
      const stringParams = qs.stringify(query, {
        encode: false,
        skipNulls: true,
      });
      router.push(`/route-flight?${stringParams}`);
    }
  };
  const title = (
    <Stack
      direction="row"
      alignItems="center"
    >
      <PassengerIcon color={style.color.black1} />
      <Typography
        variant="body1"
        sx={{
          marginLeft: '6px',
          fontWeight: '500',
        }}
      >
        {`${totalPassenger} ${lang?.SearchBar?.flightsTab?.passenger?.passenger || 'Hành khách'}`}
        {' '}
      </Typography>
    </Stack>
  );
  return (
    <PopperSelect
      title={title}
      onClickHandler={switchUpdateSecondTime}
    >
      <ChoosePassenger
        lang={lang}
        vehicleType={vehicleType}
        passenger={vehicleType === 'bus' ? stateBusPassenger : stateFlightPassenger}
        adjustPassenger={adjustPassengerFunc}
      />
    </PopperSelect>
  );
};

export default ChoosePassengerContainer;

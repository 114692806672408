import { pr } from '@/utils/pxToRem';
import {
  Divider,
  Grid,
  Stack,
} from '@mui/material';
import React from 'react';
import ChoosePassengerContainer from '../../Desktop/ChoosePassengerContainer';
// import ChooseBusBookingTypeContainer from '../../Desktop/ChooseBusBookingTypeContainer';
import SelectBusArea from '@/containers/Desktop/SelectBusArea';
import ToggleAreaContainer from '@/containers/Desktop/ToggleArea';
import { LocationSearchType } from './SearchDialog/index.d';
import SelectDate from '@/containers/Desktop/SelectDate';
import FindRouteButton from '@/containers/Desktop/FindFlightButton';
import { styled } from '@mui/system';
import { style } from '@/styles/globals';
import { FindButton } from './FindRouteButton';

interface SearchBusTabProps {
  onSearchClick?: () => void;
  onClose?: () => void;
  isShowUpdate?: boolean;
  showFullWidth?: boolean;
  isSearchRoute?: boolean;
}

export const RouteItemGrid = styled(Grid)`
  // padding-left: 1em;

  .reactSelectControl {
    min-width: 0!important;
    border: none;
    margin: 8px 16px !important;
  }
`;
export const RouteCloseButton = styled(FindButton)`
  background: ${style.color.white4};
  width: 100%;
  border: 1px solid ${style.color.product3};
  border-radius: 3px;
  margin: 0;

`;

const SearchBusTab: React.FC<SearchBusTabProps> = ({
  onSearchClick, showFullWidth, isSearchRoute,
}) => (
  <Stack
    id="searchFlightTab"
    sx={{
      p: isSearchRoute ? '12px 0 8px 0' : '12px 26px 8px 16px',
      width: showFullWidth ? '100%' : 'unset',
    }}
    spacing={pr`4px`}
  >
    <Stack
      direction="row"
      spacing={pr`32px`}
      alignItems="center"
      sx={{ marginBottom: '10px' }}
    >
      {/* <ChooseBusBookingTypeContainer /> */}
      <ChoosePassengerContainer vehicleType="bus" />
    </Stack>
    <Grid
      container
      width="100%"
      columnSpacing={{
        xs: '8px',
      }}
    >
      <Grid
        container
        width="100%"
        xs={5}
        // xs={12}
        // sm={12}
        // lg={5}
        sx={{
          position: 'relative',
          border: `1px solid ${style.color.black6}`,
          backgroundColor: 'white',
          boxSizing: 'border-box',
          borderRadius: '6px',
          marginRight: '8px',
          marginBottom: {
            xs: '8px',
            sm: '8px',
          },
          height: '56px',
        }}
      >
        <RouteItemGrid
          // item
          xs={5.9}
          sm={5.9}
          lg={5.9}
          sx={{ position: 'relative' }}
        >
          <SelectBusArea type={LocationSearchType.FROM} />
        </RouteItemGrid>
        <Grid
          item
          xs={0.2}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: '9px 0',
            position: 'relative',
            height: '56px',
          }}
        >
          <Divider
            orientation="vertical"
            sx={{
              height: '80%',
              width: '1px',
              position: 'absolute',
              margin: '9px 0',
            }}
          />
          <ToggleAreaContainer vehicleType="bus" />
        </Grid>

        <RouteItemGrid
          // item
          xs={5.9}
          sm={5.9}
          lg={5.9}
          sx={{ position: 'relative' }}
        >
          <SelectBusArea type={LocationSearchType.TO} />
        </RouteItemGrid>
      </Grid>

      {/* <RouteItemGrid
        item
        xs={12}
        sm={6}
        lg={isShowUpdate ? 2.2 : 2.7}
        sx={{ position: 'relative' }}
      >
        <SelectBusArea type={LocationSearchType.FROM} />
        <ToggleAreaContainer vehicleType="bus" />
      </RouteItemGrid>
      <RouteItemGrid
        item
        xs={12}
        sm={6}
        lg={isShowUpdate ? 2.2 : 2.7}
      >
        <SelectBusArea type={LocationSearchType.TO} />
      </RouteItemGrid> */}

      <Grid
        container
        width="100%"
        xs={5}
        // xs={12}
        // sm={12}
        // lg={5}
        sx={{
          border: `1px solid ${style.color.black6}`,
          backgroundColor: 'white',
          boxSizing: 'border-box',
          borderRadius: '6px',
          height: '56px',
          marginBottom: {
            xs: '8px',
            sm: '8px',
          },
        }}
      >
        <RouteItemGrid
          item
          xs={5.9}
          sm={5.9}
          lg={5.9}
        >
          <SelectDate
            isNotShowPrice
            type="depart"
          />
        </RouteItemGrid>
        <Grid
          item
          xs={0.2}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          sx={{
            padding: '9px 0',
            position: 'relative',
          }}
        >
          <Divider
            orientation="vertical"
            sx={{
              height: '80%',
              width: '1px',
              position: 'absolute',
              margin: '9px 0',
            }}
          />
        </Grid>
        <RouteItemGrid
          item
          xs={5.9}
          sm={5.9}
          lg={5.9}
        >
          <SelectDate
            isNotShowPrice
            type="return"
          />
        </RouteItemGrid>
      </Grid>
      {/* <RouteItemGrid
        item
        xs={12}
        sm={6}
        lg={isShowUpdate ? 2.2 : 2.7}
      >
        <SelectDate
          isNotShowPrice
          // filterAirline={filterAirline}
          type="depart"
        />
      </RouteItemGrid>
      <RouteItemGrid
        item
        xs={12}
        sm={6}
        lg={isShowUpdate ? 2.2 : 2.7}
      >
        <SelectDate
          isNotShowPrice
          // filterAirline={filterAirline}
          type="return"
        />
      </RouteItemGrid> */}
      <Grid
        item
        container
        // xs={12}
        // sm={12}
        // lg={1.5}
        xs={1.8}
        sx={{
          // marginBottom: '16px',
          height: '56px',
        }}
      >
        <FindRouteButton
          // filterAirline={filterAirline}
          vehicleType="bus"
          onSearchClick={onSearchClick}
        />
      </Grid>
      {/* <RouteItemGrid
        item
        xs={12}
        sm={12}
        lg={isShowUpdate ? 1.3 : 1.15}
        sx={{
          marginBottom: '16px',
        }}
      >
        <FindRouteButton
          // filterAirline={filterAirline}
          vehicleType="bus"
          onSearchClick={onSearchClick}
        />
      </RouteItemGrid>
      {isShowUpdate && (
      <RouteItemGrid
        item
        xs={12}
        sm={12}
        lg={1.2}
      >
        <RouteCloseButton onClick={onClose}>
          <Typography
            variant="body1"
            style={{
              color: style.color.product3,
            }}
          >
            Đóng
          </Typography>
        </RouteCloseButton>
      </RouteItemGrid>
      )} */}
    </Grid>
  </Stack>
);
export default SearchBusTab;

import PlusButton from '@/components/PlusButton';
import TwoButton from '@/components/TwoButton';
import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
import ErrorIcon from '@mui/icons-material/Error';
import { Stack, Typography } from '@mui/material';
import styled from '@mui/styled-engine';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Box from '@mui/system/Box';
import { useMemo, useState } from 'react';
import { TAdjustPassengerParams } from '../../../slices/area/slices/searchFlightFormSlice';
import type {
  IPassengerSearchForm,
  PassengerType,
} from '../../../slices/passenger/slices/type.d';
import { clearAnchorPopperSelect } from '@/utils/eventEmitter';
import { MAX_PEOPLE_PER_BOOKING } from '@/utils/constants';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';

const StyledMenuBoxContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});
const StyledMenuBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexBasis: '50%',
});

const CustomNumericTextField = ({
  type,
  value,
  adjustPassenger,
  isDisable,
}: {
  type: PassengerType;
  value: number;
  adjustPassenger: (params: TAdjustPassengerParams) => void;
  isDisable: boolean;
}) => {
  const handleIncreaseValue = () => {
    adjustPassenger({
      passengerType: type,
      value: value + 1,
    });
  };
  const handleDecreaseValue = () => {
    if (value === 0) return;
    if (value === 1 && type === 'adt') return;
    adjustPassenger({
      passengerType: type,
      value: value - 1,
    });
  };
  const shouldDisableMinusBtn = useMemo(() => {
    if (type === 'adt') {
      if (value === 1) return true;
    }
    if (value === 0) return true;
    return false;
  }, [type, value]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={pr`16px`}
    >
      <PlusButton
        type="minus"
        isDisable={shouldDisableMinusBtn}
        onClick={handleDecreaseValue}
      />
      <Typography
        variant="body2"
        color={style.color.black1}
        fontWeight="500"
      >
        {value}
      </Typography>
      <PlusButton
        type="plus"
        isDisable={isDisable}
        onClick={handleIncreaseValue}
      />
    </Stack>
  );
};

const MenuItemLayout = ({
  label,
  caption,
  type,
  warning,
  passenger,
  adjustPassenger,
  shouldWarning,
  isDisable,
}: {
  label: string;
  caption: string;
  type: PassengerType;
  warning: string;
  passenger: IPassengerSearchForm;
  adjustPassenger: (params: TAdjustPassengerParams) => void;
  shouldWarning: boolean;
  isDisable: boolean;
}) => {
  const disabled = useMemo(() => {
    if (isDisable) return true;
    if (shouldWarning) return true;
    return false;
  }, [isDisable, shouldWarning]);
  return (
    <div>
      <StyledMenuBoxContainer>
        <Stack>
          <StyledMenuBox style={{ justifyContent: 'start' }}>
            <Typography
              variant="body2"
              fontWeight="bold"
            >
              {label}
            </Typography>
          </StyledMenuBox>
          <StyledMenuBox style={{ justifyContent: 'center' }}>
            <Typography variant="caption">{caption}</Typography>
          </StyledMenuBox>
        </Stack>
        <StyledMenuBox style={{ justifyContent: 'flex-end' }}>
          <CustomNumericTextField
            type={type}
            isDisable={disabled}
            value={passenger[type]}
            adjustPassenger={adjustPassenger}
          />
        </StyledMenuBox>
      </StyledMenuBoxContainer>

      <Stack
        direction="row"
        alignItems="center"
        spacing="0.2em"
        sx={{
          display: shouldWarning ? 'flex' : 'none',
        }}
      >
        <ErrorIcon
          sx={{
            width: '14px',
            height: '14px',
          }}
          color="error"
        />
        <Typography
          variant="caption"
          color="error"
        >
          {warning}
        </Typography>
      </Stack>
    </div>
  );
};

const defaultWarningObj = {
  shouldChildWarning: false,
  shouldInfantWarning: false,
  shouldAdultWarning: false,
};
const ChoosePassenger = ({
  passenger,
  adjustPassenger,
  vehicleType,
  lang,
}: {
  passenger: IPassengerSearchForm;
  adjustPassenger: (params: IPassengerSearchForm) => void;
  vehicleType?: 'bus' | 'flight' | undefined;
  lang?: ILangObj;
}) => {
  // eslint-disable-next-line max-len
  const [tempPassenger, setTempPassenger] = useState<IPassengerSearchForm>(passenger);
  const [warningObj, setWarningObj] = useState(defaultWarningObj);

  const tempAdjustPassenger = ({
    value,
    passengerType: type,
  }: TAdjustPassengerParams) => {
    let { shouldAdultWarning, shouldChildWarning, shouldInfantWarning } = warningObj;
    let { adt, chd, inf } = tempPassenger;

    switch (type) {
      case 'adt':
        if (value < adt) {
          if (chd > 2 && chd > 2 * value) {
            const diffAdt = adt - value;
            chd = Math.round(chd / (diffAdt * 2));
          }
          if (inf > 1 && inf > value) {
            inf = value;
          }
        }
        adt = value;
        shouldChildWarning = chd > 2 * adt;
        shouldInfantWarning = inf > adt;
        break;
      case 'chd':
        shouldChildWarning = value > 2 * adt;
        chd = value;
        break;
      case 'inf':
        shouldInfantWarning = value > adt;
        inf = value;
        break;
      default:
        break;
    }

    shouldAdultWarning = adt + chd + inf > MAX_PEOPLE_PER_BOOKING;
    setWarningObj({
      shouldAdultWarning,
      shouldChildWarning,
      shouldInfantWarning,
    });
    if (
      (shouldChildWarning && type === 'chd')
      || (shouldInfantWarning && type === 'inf')
      || shouldAdultWarning
    ) {
      return;
    }
    setTempPassenger({
      adt,
      chd,
      inf,
      [type]: value,
    });
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        setTempPassenger(passenger);
        setWarningObj(defaultWarningObj);
      }}
    >
      <Stack
        spacing="1.75em"
        sx={{
          p: pr`16px`,
          boxShadow: '0px 2px 10px rgb(7 65 57 / 15%)',
          borderRadius: '6px',
          background: style.color.white4,
        }}
      >
        <Stack spacing={pr`16px`}>
          {vehicleType === 'bus' ? (
            <Stack spacing="1.5em">
              <MenuItemLayout
                label="Hành khách"
                type="adt"
                caption="Số lượng hành khách từ 2 tuổi trở lên"
                warning={`Bạn chỉ có thể đặt ${MAX_PEOPLE_PER_BOOKING} hành khách cùng lúc`}
                passenger={tempPassenger}
                adjustPassenger={tempAdjustPassenger}
                shouldWarning={warningObj.shouldAdultWarning}
                isDisable={warningObj.shouldAdultWarning}
              />
            </Stack>
          ) : (
            <Stack spacing="1.5em">
              <MenuItemLayout
                label={lang?.SearchBar?.flightsTab?.passenger?.adult || 'Người lớn'}
                type="adt"
                caption={lang?.SearchBar?.flightsTab?.passenger?.adultDesc || 'Từ đúng 12 tuổi trở lên vào ngày khởi hành'}
                warning={`${lang?.SearchBar?.flightsTab?.passenger?.warningNumberPassenger?.[0] || 'Bạn chỉ có thể đặt'} ${MAX_PEOPLE_PER_BOOKING} ${lang?.SearchBar?.flightsTab?.passenger?.warningNumberPassenger?.[1] || 'hành khách cùng lúc'}`}
                passenger={tempPassenger}
                adjustPassenger={tempAdjustPassenger}
                shouldWarning={warningObj.shouldAdultWarning}
                isDisable={warningObj.shouldAdultWarning}
              />
              <MenuItemLayout
                label={lang?.SearchBar?.flightsTab?.passenger?.child || 'Trẻ em'}
                type="chd"
                caption={lang?.SearchBar?.flightsTab?.passenger?.childDesc || 'Từ đúng 2 tuổi đến dưới 11 tuổi'}
                warning={lang?.SearchBar?.flightsTab?.passenger?.childNoti || 'Số trẻ em không được quá 2 lần số người lớn'}
                passenger={tempPassenger}
                adjustPassenger={tempAdjustPassenger}
                shouldWarning={warningObj.shouldChildWarning}
                isDisable={warningObj.shouldAdultWarning}
              />
              <MenuItemLayout
                label={lang?.SearchBar?.flightsTab?.passenger?.infant || 'Em bé'}
                type="inf"
                caption={lang?.SearchBar?.flightsTab?.passenger?.infantDesc || 'Từ đúng 14 ngày đến dưới 2 tuổi'}
                warning={lang?.SearchBar?.flightsTab?.passenger?.noti || 'Mỗi người lớn chỉ được đi kèm 1 em bé'}
                passenger={tempPassenger}
                adjustPassenger={tempAdjustPassenger}
                shouldWarning={warningObj.shouldInfantWarning}
                isDisable={warningObj.shouldAdultWarning}
              />
            </Stack>
          )}
        </Stack>
        <TwoButton
          leftTitle={lang?.global?.cancel || 'Huỷ'}
          leftVariant="contained"
          rightVariant="contained"
          rightTitle={lang?.global?.apply || 'Áp dụng'}
          onLeftClick={() => {
            setTempPassenger(passenger);
            setWarningObj(defaultWarningObj);
            clearAnchorPopperSelect();
          }}
          onRightClick={() => {
            adjustPassenger(tempPassenger);
            setWarningObj(defaultWarningObj);
            clearAnchorPopperSelect();
          }}
        />
      </Stack>
    </ClickAwayListener>
  );
};

export default ChoosePassenger;

import FindRouteFlightButton from '@/containers/SearchFlightTab/FindRouteButton';
import FindRouteBusButton from '@/containers/Bus/SearchBusTab/FindRouteButton';
import { useAppSelector } from '@/utils/hook';
import React from 'react';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';

const FindFlightButton = ({
  vehicleType,
  onSearchClick,
  filterAirline,
  seoStyle,
  isVcms,
  lang,
}: {
  onSearchClick?: () => void;
  filterAirline?: any;
  vehicleType?: 'bus' | 'flight',
  seoStyle?: boolean,
  isVcms?: boolean;
  lang?: ILangObj;
}) => {
  const stateSelectedDate = useAppSelector(
    (state) => state.calendar.selectedDate,
  );
  // const [startPoint, setStartPoint] = useState<IFlightResultItem | null>(stateStartPoint);
  // const [toPoint, setToPoint] = useState<IFlightResultItem | null>(stateToPoint);
  const searchFlightForm = useAppSelector(
    (state) => state.searchFlightForm,
  );
  const searchBusForm = useAppSelector(
    (state) => state.searchBusForm,
  );
  const startPoint = vehicleType === 'bus' ? searchBusForm.startPointHome : searchFlightForm.startPointHome;
  const toPoint = vehicleType === 'bus' ? searchBusForm.toPointHome : searchFlightForm.toPointHome;
  // const [selectedDate, setSelectedDate] = useState<TSetSelectedDateParams>(stateSelectedDate);
  const selectedDepartDate = stateSelectedDate && stateSelectedDate.depart ? new Date(stateSelectedDate.depart) : new Date();
  const selectedReturnDate = stateSelectedDate && stateSelectedDate.return
    ? new Date(stateSelectedDate.return)
    : null;
  const selectedDate = {
    depart: selectedDepartDate,
    return: selectedReturnDate,
  };
  return (
    <>
      { vehicleType === 'bus' ? (
        <FindRouteBusButton
          style={{
            height: '100%',
            margin: 0,
            borderRadius: '3px',
          }}
          onSearchClick={onSearchClick}
          tmpStartPoint={startPoint}
          tmpToPoint={toPoint}
          tmpSelectedDate={selectedDate}
          tmpPassenger={searchBusForm.passenger}
          isShowCalendarVietNam={false}
        />
      ) : (
        <FindRouteFlightButton
          seoStyle={seoStyle}
          filterAirline={filterAirline}
          style={{
            height: '100%',
            margin: 0,
            borderRadius: '3px',
          }}
          onSearchClick={onSearchClick}
          tmpStartPoint={startPoint}
          tmpToPoint={toPoint}
          tmpSelectedDate={selectedDate}
          tmpPassenger={searchFlightForm.passenger}
          tmpSeatClass={searchFlightForm.seatClass}
          isShowCalendarVietNam={false}
          isVcms={isVcms}
          lang={lang}
        />

      ) }
    </>
  );
};

export default FindFlightButton;

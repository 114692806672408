import { style } from '@/styles/globals';
import { IconButton } from '@mui/material';
import { Box, SxProps, Theme } from '@mui/system';
import { VectorDesktopIcon } from 'public/icons';
import React from 'react';

interface ToggleAreaProps {
  onClick: () => void;
  sx?: SxProps<Theme>
  seoStyle?: boolean;
}

const ToggleArea: React.FC<ToggleAreaProps> = ({ onClick, sx, seoStyle }) => (
  <Box
    sx={
      {
        // position: 'absolute',
        ...sx,
      }
    }
  >
    <IconButton
      sx={{
        p: '5px',
        width: '32px',
        height: '32px',
      }}
      onClick={onClick}
    >
      <VectorDesktopIcon color={seoStyle ? style.primary.blue : style.color.product1} />
    </IconButton>
  </Box>
);

export default ToggleArea;

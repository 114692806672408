import React, { useEffect } from 'react';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Button from '@mui/material/Button';
import eventEmitter, { CLEAR_ANCHOR_POPPER_SELECT } from '@/utils/eventEmitter';
import { style } from '@/styles/globals';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface PopperSelectProps {
  children: any;
  title: any;
  onClickHandler?: any;
}

const PopperSelect: React.FC<PopperSelectProps> = ({ children, title, onClickHandler }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClickAway = () => {
    setAnchorEl(null);
    if (onClickHandler) {
      onClickHandler();
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  useEffect(() => {
    const event = eventEmitter.addListener(CLEAR_ANCHOR_POPPER_SELECT, () => {
      setAnchorEl(null);
    });
    return () => {
      event.remove();
    };
  }, []);
  return (
    <ClickAwayListener
      onClickAway={handleClickAway}
    >
      <div>
        <Button
          variant="text"
          onClick={handleClick}
          sx={{
            padding: 0,
            textTransform: 'none',
            color: style.color.black2,
            ...style.text.content.bold,
          }}
        >
          {title}
          <KeyboardArrowDownIcon />
        </Button>
        <Popper
          id={id}
          style={{
            zIndex: 100,
          }}
          open={open}
          anchorEl={anchorEl}
          placement="bottom-start"
        >
          {children}
        </Popper>
      </div>

    </ClickAwayListener>
  );
};

export default PopperSelect;

import { style } from '@/styles/globals';
import { pr } from '@/utils/pxToRem';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import { Checkbox, Stack, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import styled from '@mui/styled-engine';
import Box from '@mui/system/Box';
import { useRouter } from 'next/router';
// import { LeadingIcon } from '../../../../public/icons';
import type { SeatClassType } from './type';
import qs from 'qs';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';

const StyledBox = styled(Box)({
  background: style.color.black7,
  borderRadius: pr`16px`,
  display: 'flex',
  alignItems: 'flex-end',
  marginTop: pr`8px`,
  transitionDelay: '0.1s',
  '&:hover': {
    background: '#EEF2F4',
  },
});

export const SelectStyledBox = styled(StyledBox)({
  // paddingTop: '10px',
  alignItems: 'center',
  boxSizing: 'border-box',
  height: 46,
  '.css-1r9yfjo-MuiSelect-select-MuiInputBase-input-MuiInput-input': {
    paddingRight: '8px !important',
  },
});

const StyledMenuBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
});
const MenuItemLayout = ({
  label,
  caption,
  checked,
  onCheck,
}: {
  label: string;
  caption: string;
  checked: boolean;
  onCheck: () => void;
}) => {
  const handleAdjustSeatClass = () => {
    onCheck();
  };
  return (
    <Stack
      onClick={(e) => {
        e.stopPropagation();
        handleAdjustSeatClass();
      }}
      alignItems="center"
      direction="row"
      spacing={pr`16px`}
    >
      <Stack
        style={{
          justifyContent: 'start',
          flexBasis: '90%',
        }}
      >
        <Typography
          variant="body2"
          fontWeight="bold"
        >
          {label}
        </Typography>
        <Typography variant="caption">{caption}</Typography>
      </Stack>
      <StyledMenuBox
        style={{
          justifyContent: 'end',
          flexBasis: '10%',
        }}
      >
        <Checkbox
          style={{ color: style.color.blue3 }}
          icon={<RadioButtonUncheckedOutlinedIcon />}
          checkedIcon={<RadioButtonCheckedOutlinedIcon />}
          checked={checked}
        />
      </StyledMenuBox>
    </Stack>
  );
};

const ChooseSeatClass = ({
  seatClass,
  adjustSeatClass,
  lang,
}: {
  seatClass: SeatClassType;
  adjustSeatClass: (param: SeatClassType) => void;
  lang?: ILangObj;
}) => {
  const router = useRouter();

  const chooseSeatClassHandler = (seat: SeatClassType) => {
    adjustSeatClass(seat);
    const { query, asPath } = router;
    if (asPath.includes('/route-flight')) {
      query.fare_class = seat;
      const stringParams = qs.stringify(query, {
        encode: false,
        skipNulls: true,
      });
      router.replace(`/route-flight?${stringParams}`, undefined, { shallow: true });
    }
  };

  return (
    /* eslint-disable max-len */
    <>
      <ClickAwayListener
        onClickAway={() => {
          adjustSeatClass(seatClass);
        }}
      >
        <Stack
          spacing="1.75em"
          sx={{
            p: pr`16px`,
            boxShadow: '0px 2px 10px rgb(7 65 57 / 15%)',
            borderRadius: '6px',
            background: style.color.white4,
          }}
        >
          <Stack spacing={pr`16px`}>
            {/* <Stack>
              <Typography variant="h6">Loại vé</Typography>
            </Stack> */}
            <Stack spacing="1.2em">
              <MenuItemLayout
                label={lang?.SearchBar?.flightsTab?.seatClass?.economy || 'Phổ thông'}
                caption={lang?.SearchBar?.flightsTab?.seatClass?.economyDesc || 'Bay tiết kiệm, đáp ứng mọi nhu cầu cơ bản của bạn'}
                checked={seatClass === 'PT'}
                onCheck={() => chooseSeatClassHandler('PT')}
              />
              <MenuItemLayout
                label={lang?.SearchBar?.flightsTab?.seatClass?.premiumEconomy || 'Phổ thông đặc biệt'}
                caption={lang?.SearchBar?.flightsTab?.seatClass?.premiumEconomyDesc || 'Chi phí hợp lý với bữa ăn ngon và chỗ để chân rộng rãi'}
                checked={seatClass === 'PTDB'}
                onCheck={() => chooseSeatClassHandler('PTDB')}
              />
              <MenuItemLayout
                label={lang?.SearchBar?.flightsTab?.seatClass?.business || 'Thương gia'}
                caption={lang?.SearchBar?.flightsTab?.seatClass?.businessDesc || 'Bay đẳng cấp, với quầy làm thủ tục và khu ghế ngồi riêng'}
                checked={seatClass === 'TG'}
                onCheck={() => chooseSeatClassHandler('TG')}
              />
              {/* enable if international route, support later
            <MenuItemLayout
              label="Hạng nhất"
              type="First Class"
              caption="Hạng cao cấp nhất, với dịch vụ 5 sao được cá nhân hóa"
            /> */}
            </Stack>
          </Stack>
        </Stack>
      </ClickAwayListener>
    </>
  );
};
export default ChooseSeatClass;

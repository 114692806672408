import ChooseSeatClass from '@/components/desktop/ChooseSeatClass';
import PopperSelect from '@/components/desktop/PopperSelect';
import { adjustSeatClass } from '@/slices/area/slices/searchFlightFormSlice';
import { clearAnchorPopperSelect } from '@/utils/eventEmitter';
import { useAppDispatch, useAppSelector } from '@/utils/hook';
import { mappingTextFromSeatClass } from '@/utils/seatClassMapping';
import React from 'react';
import { SeatClassIcon } from 'public/icons';
import { Stack, Typography } from '@mui/material';
import { style } from '@/styles/globals';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';

const ChooseSeatClassContainer = ({
  lang, isFirstTime, switchUpdateSecondTime,
  stateSeatClass,
}: { lang?: ILangObj, isFirstTime?: boolean,
  switchUpdateSecondTime?: any,
  stateSeatClass?: any, }) => {
  let { seatClass } = useAppSelector(
    (state) => state.searchFlightForm,
  );
  if (isFirstTime) {
    seatClass = stateSeatClass;
  }

  const dispatch = useAppDispatch();
  const adjustSeatClassFunc = (value: any) => {
    dispatch(adjustSeatClass({ seatClass: value }));
    clearAnchorPopperSelect();
  };
  const title = (
    <Stack
      direction="row"
      alignItems="center"
    >
      <SeatClassIcon color={style.color.black1} />
      <Typography
        variant="body1"
        sx={{
          marginLeft: '6px',
          fontWeight: '500',
        }}
      >
        {`${mappingTextFromSeatClass(seatClass, lang)} `}
      </Typography>
    </Stack>
  );
  return (
    <PopperSelect
      title={title}
      onClickHandler={switchUpdateSecondTime}
    >
      <ChooseSeatClass
        lang={lang}
        seatClass={seatClass}
        adjustSeatClass={adjustSeatClassFunc}
      />
    </PopperSelect>

  );
};

export default ChooseSeatClassContainer;

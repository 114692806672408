import ToggleArea from '@/components/desktop/ToggleArea';
import { addAllHomePoints as addAllHomePointsFlight } from '@/slices/area/slices/searchFlightFormSlice';
import { addAllHomePoints as addAllHomePointsBus } from '@/slices/bus/area/slices/searchBusFormSlice';
import { style } from '@/styles/globals';
import { useAppDispatch, useAppSelector } from '@/utils/hook';
import { pr } from '@/utils/pxToRem';
import React from 'react';

const ToggleAreaContainer = ({ vehicleType, seoStyle }: { vehicleType?: 'flight' | 'bus', seoStyle?: boolean }) => {
  const { startPointHome, toPointHome } = useAppSelector(
    (state) => (vehicleType === 'bus' ? state.searchBusForm : state.searchFlightForm),
  );
  const startPoint = startPointHome;
  const toPoint = toPointHome;
  const dispatch = useAppDispatch();
  const handleClick = () => {
    if (vehicleType === 'bus') {
      dispatch(addAllHomePointsBus([
        {
          data: startPoint,
          type: 'to',
        }, {
          data: toPoint,
          type: 'from',
        },
      ]));
    } else {
      dispatch(addAllHomePointsFlight([
        {
          data: startPoint,
          type: 'to',
        }, {
          data: toPoint,
          type: 'from',
        },
      ]));
    }
  };
  return (
    <ToggleArea
      seoStyle={seoStyle}
      onClick={handleClick}
      sx={{
        top: {
          xs: '60%',
          lg: '0.5em',
          sm: pr`6px`,
        },
        right: {
          xs: '45%',
          lg: '-1.5em',
          sm: pr`-22px`,
        },
        background: style.color.white,
        border: `solid 1.5px ${style.color.black6}`,
        // boxShadow: '0px 2px 10px rgba(7, 65, 57, 0.15)',

        zIndex: 1,
        borderRadius: '50%',
        // width: '32px',
        // height: '32px',
      }}
    />
  );
};

export default ToggleAreaContainer;

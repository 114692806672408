import { TSetSelectedDateParams } from '@/components/CustomCalendar/type';
import CalendarList from '@/components/desktop/CalendarList';
import IOSSwitch from '@/components/IosSwitch';
import {
  setChooseReturn,
  setDepartDate,
  setReturnDate,
  setSelectedDate,
  setShowCalendarVietNam,
} from '@/slices/calendar/calendarSlice';
import {
  TMinFareObj,
  useLazyGetFlightStatistics2Query,
} from '@/slices/flightData/apis/flightDataApi';
import { style } from '@/styles/globals';
import { getAreaPointArr } from '@/utils';
import { clearAnchorPopperInput } from '@/utils/eventEmitter';
import { useAppDispatch, useAppSelector } from '@/utils/hook';
import { pr } from '@/utils/pxToRem';
import {
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import { format } from 'date-fns';
import { vi } from 'date-fns/locale';
import { enUS } from 'date-fns/locale';
import React, { useMemo } from 'react';
import PopperInputCalendar from '../PopperInputCalendar';
import { ContentContainer } from '../SelectArea';
import { monthYears } from '@/utils/monthYears';
import Add from '@mui/icons-material/Add';
import { resetSortingBusRoute } from '@/slices/bus/sortingBusRoute/sortingBusRouteSlice';
import { resetSortingFlight } from '@/slices/sortingFlight/sortingFlightSlice';
import { TGenerateFlightCountRequest } from '@/utils/generateFlightRequest';
import { useRouter } from 'next/router';
import { getParamsBusMapping, getParamsFlightMapping } from '@/utils/paramsMapping';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';
// import { IS_SHOW_CALENDAR_VN } from '@/utils/constants';

export const VectorIconWrapper = styled('div')({
  display: 'inline',
  background: style.color.white,
  borderRadius: '50%',
  position: 'absolute',
  top: '2em',
  right: '2em',
  boxShadow: style.boxShadow.customShadow,
});

interface SelectDateProps {
  type: 'depart' | 'return';
  filterAirline?: any;
  isNotShowPrice?: boolean;
  seoStyle?: boolean;
  lang?: ILangObj;
  isFirstTime?: boolean;
}

const SelectDate: React.FC<SelectDateProps> = ({
  type, filterAirline, isNotShowPrice, seoStyle, lang, isFirstTime,
}) => {
  const router = useRouter();
  const { selectedDate: stateSelectedDate } = useAppSelector((state) => state.calendar);
  const { startPointHome: startPointHomeFlight, toPointHome: toPointHomeFlight, passenger: passengerFlight } = useAppSelector((state) => state.searchFlightForm);
  const { startPointHome: startPointHomeBus, toPointHome: toPointHomeBus, passenger: passengerBus } = useAppSelector((state) => state.searchBusForm);

  const { seatClass } = useAppSelector((state) => state.searchFlightForm);

  const startPoint = startPointHomeFlight;
  const toPoint = toPointHomeFlight;

  const dispatch = useAppDispatch();

  const selectedDepartDate = stateSelectedDate && stateSelectedDate.depart ? new Date(stateSelectedDate.depart) : new Date();
  const selectedReturnDate = stateSelectedDate && stateSelectedDate.return
    ? new Date(stateSelectedDate.return)
    : null;
  const selectedDate = isFirstTime ? {
    depart: new Date(),
    return: null,
  } : {
    depart: selectedDepartDate,
    return: selectedReturnDate,
  };
  const setSelectedDateFunc = (params: TSetSelectedDateParams) => {
    if (
      type === 'depart'
      && params
      && params.depart !== undefined
      && params.depart !== selectedDepartDate
    ) {
      if (params?.return) {
        dispatch(
          setSelectedDate({
            dateObj: JSON.stringify(params),
          }),
        );
      } else if (params && params.depart) {
        dispatch(
          setDepartDate({
            depart: params.depart.toISOString(),
          }),
        );
      }
      dispatch(resetSortingBusRoute());
      dispatch(resetSortingFlight());
    }
    if (
      type === 'return'
      && params
      && params.return !== null
      && params.return !== selectedReturnDate
    ) {
      dispatch(
        setReturnDate({
          return: params?.return.toISOString(),
        }),
      );
      dispatch(resetSortingBusRoute());
      dispatch(resetSortingFlight());
    }
    setTimeout(() => {
      clearAnchorPopperInput();
    }, 100);
  };

  const [getMinFare, { isLoading, data }] = useLazyGetFlightStatistics2Query();

  // const [getMinFare, { isLoading, data }] = useLazyGetFlightStatistics2Query();

  const flightRequestDepart: TGenerateFlightCountRequest = {
    from: startPoint ? getAreaPointArr(startPoint) : [],
    to: toPoint ? getAreaPointArr(toPoint) : [],
    month_years: monthYears(),
    flight_class: seatClass,
    quantity: passengerFlight ? (passengerFlight.adt + passengerFlight.chd) : 0,
    child_infant_count: passengerFlight ? (passengerFlight.chd + passengerFlight.inf) : 0,
    // disable_update_filter: 1,
  };

  const flightRequestReturn: TGenerateFlightCountRequest = {
    from: toPoint ? getAreaPointArr(toPoint) : [],
    to: startPoint ? getAreaPointArr(startPoint) : [],
    month_years: monthYears(),
    flight_class: seatClass,
    quantity: passengerFlight ? (passengerFlight.adt + passengerFlight.chd) : 0,
    child_infant_count: passengerFlight ? (passengerFlight.chd + passengerFlight.inf) : 0,
    // disable_update_filter: 1,
  };

  if (filterAirline) {
    flightRequestDepart.airline = filterAirline;
  }

  const dayPriceObj = {} as TMinFareObj;
  data?.forEach((group) => {
    Object.keys(group?.grouped_by_date).forEach((groupedDate) => {
      dayPriceObj[groupedDate] = group?.grouped_by_date[groupedDate].min_fare;
    });
  });
  const isShowCalendarVietNam = useAppSelector(
    (state) => state.calendar.showCalendarVietNam,
  );
  // React.useEffect(() => {
  //   // @ts-ignore
  //   const showCalendarVietNam = !!+window.localStorage.getItem(
  //     IS_SHOW_CALENDAR_VN,
  //   );
  //   dispatch(setShowCalendarVietNam({ value: showCalendarVietNam }));
  // }, []);

  const handleOnClick = () => {
    const isReturn = type === 'return' && !selectedDate.return;
    if (startPoint && toPoint && type === 'return') {
      getMinFare(flightRequestReturn);
    } else if (startPoint && toPoint) {
      getMinFare(flightRequestDepart);
    }
    if (isReturn) {
      dispatch(setChooseReturn({ chooseReturn: true }));
    }
    window.scrollTo({
      top: 115,
      left: 0,
      behavior: 'smooth',
    });
  };
  const hanleClearReturnDate = () => {
    dispatch(setChooseReturn({ chooseReturn: false }));
    //     setSelectedReturnDate(null);
    dispatch(setReturnDate({ return: null }));
    dispatch(resetSortingBusRoute());
    dispatch(resetSortingFlight());
    setTimeout(() => {
      clearAnchorPopperInput();
    }, 200);
    const vehicleChangeRoute = router?.asPath?.includes('/bus/') ? 'bus' : router?.asPath?.includes('/route-flight') ? 'flight' : null;
    if (type === 'return' && vehicleChangeRoute) {
      const path = vehicleChangeRoute === 'bus' ? getParamsBusMapping({
        tmpPassenger: passengerBus,
        tmpSelectedDate: {
          depart: `${selectedDepartDate}`,
          return: null,
        },
        tmpStartPoint: startPointHomeBus || undefined,
        tmpToPoint: toPointHomeBus || undefined,
      }) : getParamsFlightMapping({
        tmpPassenger: passengerFlight,
        tmpSeatClass: seatClass,
        tmpSelectedDate: {
          depart: `${selectedDepartDate}`,
          return: null,
        },
        tmpStartPoint: startPointHomeFlight || undefined,
        tmpToPoint: toPointHomeFlight || undefined,
        filterAirline,
      });
      router.push(path);
    }
  };
  const dateLabel = useMemo(() => {
    if (type === 'depart') {
      return format(new Date(selectedDate.depart), lang?.lang === 'en' ? 'eeee, do LLLL, yyyy' : "ccc, dd 'th' MM, yyyy", {
        locale: lang?.lang === 'en' ? enUS : vi,
      });
    }
    if (selectedDate.return) {
      return format(new Date(selectedDate.return), lang?.lang === 'en' ? 'eeee, do LLLL, yyyy' : "ccc, dd 'th' MM, yyyy", {
        locale: lang?.lang === 'en' ? enUS : vi,
      });
    }
    return (
      <Typography
        style={{
          width: '100%',
          textAlign: 'left',
          fontSize: '1rem',
          fontWeight: 'bold',
        }}
        variant="body2"
        color={seoStyle ? style.primary.blue : style.color.product3}
      >
        <Add fontSize="inherit" />
        {lang?.SearchBar?.flightsTab?.returnDate || 'Thêm ngày về'}
        <Typography
          variant="body2"
          color={style.color.black4}
        >
          (
          {lang?.SearchBar?.flightsTab?.optional || 'Không bắt buộc'}
          )
        </Typography>
      </Typography>
    );
  }, [selectedDate]);

  const title = useMemo(() => {
    if (type === 'depart') {
      return lang?.SearchBar?.flightsTab?.departureDate || 'Ngày đi';
    }
    if (selectedDate.return) {
      return lang?.SearchBar?.flightsTab?.returnDate || 'Ngày về';
    }
    return null;
  }, [selectedDate]);

  return (
    <PopperInputCalendar
      type={type}
      dateLabel={dateLabel}
      onClick={handleOnClick}
      onClearReturnDate={hanleClearReturnDate}
      title={title}
    >
      <ContentContainer
        style={{
          padding: `${pr`16px`} 0`,
          overflow: 'hidden',
        }}
      >
        <Stack
          sx={{
            zIndex: 10,
          }}
        >
          <FormGroup
            style={{
              display: 'flex',
              textAlign: 'end',
              marginBottom: pr`10`,
            }}
          >
            <FormControlLabel
              style={{
                justifyContent: 'flex-end',
                marginLeft: '10px',
              }}
              componentsProps={{
                typography: {
                  ...style.text.content.regular,
                  color: style.color.black2,
                },
              }}
              control={(
                <IOSSwitch
                  size="small"
                  color="secondary"
                  defaultChecked={isShowCalendarVietNam}
                />
              )}
              label={(
                <Typography
                  variant="body2"
                  sx={{ marginLeft: '8px' }}
                >
                  {lang?.SearchBar?.flightsTab?.calendar?.lunarCalendar || 'Lịch âm'}
                </Typography>
              )}
              defaultChecked={isShowCalendarVietNam}
              onChange={(value: any) => dispatch(
                setShowCalendarVietNam({ value: value?.target?.checked }),
              )}
            />
          </FormGroup>
        </Stack>
        {isLoading ? (
          <div
            style={{
              width: '100%',
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <CalendarList
            lang={lang}
            isNotShowPrice={isNotShowPrice}
            type={type}
            dayPriceObj={dayPriceObj}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDateFunc}
          />
        )}
      </ContentContainer>
    </PopperInputCalendar>
  );
};

export default SelectDate;

/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/display-name */
import CustomSelect from '@/components/desktop/CustomSelect';
import { style } from '@/styles/globals';
import eventEmitter, { CLEAR_ANCHOR_POPPER_INPUT } from '@/utils/eventEmitter';
import { useAppSelector } from '@/utils/hook';
import ClearIcon from '@mui/icons-material/Clear';
import {
  IconButton, Stack, Typography, Box,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import React, { useEffect, useMemo } from 'react';
import { components, ControlProps } from 'react-select';

interface PopperInputProps {
  children: any;
  title: string | null;
  type: 'depart' | 'return';
  dateLabel: any;
  onClick?: () => void;
  onClearReturnDate?: () => void;
}

const PopperInputCalendar: React.FC<PopperInputProps> = ({
  children,
  title,
  type,
  dateLabel,
  onClick,
  onClearReturnDate,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const stateSelectedDate = useAppSelector(
    (state) => state.calendar.selectedDate,
  );
  const Control = useMemo(
    () => ({ children, ...props }: ControlProps<any, true>) => (
      <components.Control
        {...props}
        className="reactSelectControl"
      >
        <Stack
          direction="column"
          sx={{
            // p: pr`12px`,
            width: '100%',
          }}
        // alignItems="center"
        >
          {title && (
            <Typography
              variant="body2"
              color={style.color.black4}
            // fontWeight="500"
            >
              {title}
            </Typography>
          )}
          {children}
        </Stack>
      </components.Control>
    ),
    [title],
  );
  useEffect(() => {
    const event = eventEmitter.addListener(CLEAR_ANCHOR_POPPER_INPUT, () => {
      setAnchorEl(null);
    });
    return () => {
      event.remove();
    };
  }, []);
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <Box
          style={{ position: 'relative' }}
          onClick={handleClick}
        >
          <CustomSelect
            value={{
              value: '',
              label: dateLabel,
            }}
            Control={Control}
            isSearchable={false}
            onExit={() => { }}
            onChange={() => { }}
            onInputChange={() => { }}
          />
          {type === 'return' && stateSelectedDate.return && (
            <IconButton
              sx={{
                position: 'absolute',
                top: '8px',
                right: '6px',

                background: style.color.black7,
                p: '4px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (type === 'return' && stateSelectedDate.return) {
                  if (onClearReturnDate) {
                    onClearReturnDate();
                  }
                }
              }}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        <Popper
          id={id}
          open={open}
          style={{
            zIndex: 2000,
          }}
          anchorEl={anchorEl}
          placement={type === 'depart' ? 'bottom' : 'bottom-end'}
        >
          {children}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default PopperInputCalendar;
